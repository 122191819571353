import {
	DELET_BUNDLE_ERROR,
	DELET_BUNDLE_REQUEST,
	DELET_BUNDLE_SUCCESS,
	GET_BUNDLE_FREE_SUCCESS,
	GET_BUNDLE_FREE_ERROR,
	GET_BUNDLE_FREE_REQUEST,
	GET_BUNDLE_ERROR,
	GET_BUNDLE_REQUEST,
	GET_BUNDLE_SUCCESS,
	GET_CLIENTS_OF_ASSOCIATED_ERROR,
	GET_CLIENTS_OF_ASSOCIATED_REQUEST,
	GET_CLIENTS_OF_ASSOCIATED_SUCCESS,
	GET_ORDERS_ASSOCIATED_ERROR,
	GET_ORDERS_ASSOCIATED_REQUEST,
	GET_ORDERS_ASSOCIATED_SUCCESS,
	GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_ERROR,
	GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_REQUEST,
	GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_SUCCESS,
	POST_BUNDLE_ERROR,
	POST_BUNDLE_REQUEST,
	POST_BUNDLE_SUCCESS,
	POST_ORDER_UPDATE_TRACKING_ASSOCIATED_ERROR,
	POST_ORDER_UPDATE_TRACKING_ASSOCIATED_REQUEST,
	POST_ORDER_UPDATE_TRACKING_ASSOCIATED_SUCCESS,
	POST_ORDER_UPDATE_VALUE_ASSOCIATED_ERROR,
	POST_ORDER_UPDATE_VALUE_ASSOCIATED_REQUEST,
	POST_ORDER_UPDATE_VALUE_ASSOCIATED_SUCCESS,
	GET_CLIENTS_ORDERS_OF_ASSOCIATED_REQUEST,
	GET_CLIENTS_ORDERS_OF_ASSOCIATED_ERROR,
	GET_CLIENTS_ORDERS_OF_ASSOCIATED_SUCCESS,
	GET_PROFIT_PAID_REQUEST,
	GET_PROFIT_PAID_ERROR,
	GET_PROFIT_PAID_SUCCESS,
	GET_PROFIT_PENDINGOFPAY_REQUEST,
	GET_PROFIT_PENDINGOFPAY_ERROR,
	GET_PROFIT_PENDINGOFPAY_SUCCESS,
	DELET_ORDER_ASSOCIATED_REQUEST,
	DELET_ORDER_ASSOCIATED_ERROR,
	DELET_ORDER_ASSOCIATED_SUCCESS,
	GET_BUNDLE_PROFIT_REQUEST,
	GET_BUNDLE_PROFIT_ERROR,
	GET_BUNDLE_PROFIT_SUCCESS,
	GET_BUNDLE_ASSOCIATE_REQUEST,
	GET_BUNDLE_ASSOCIATE_ERROR,
	GET_BUNDLE_ASSOCIATE_SUCCESS,
	RESET_ASSOCIATED,
	POST_INCOMPLETE_ORDER_REQUEST,
	POST_INCOMPLETE_ORDER_ERROR,
	POST_INCOMPLETE_ORDER_SUCCESS,
	GET_BUNDLE_INVOICE_ASSOCIATE_ERROR,
	GET_BUNDLE_INVOICE_ASSOCIATE_REQUEST,
	GET_BUNDLE_INVOICE_ASSOCIATE_SUCCESS,
} from '../../consts/actionTypes';
import {
	DELETE_ITEM_PURCHASE_ASSOCIATED_ERROR,
	DELETE_ITEM_PURCHASE_ASSOCIATED_REQUEST,
	DELETE_ITEM_PURCHASE_ASSOCIATED_SUCCESS,
	GET_DROPSHIPPING_ASSOCIATED_ERROR,
	GET_DROPSHIPPING_ASSOCIATED_REQUEST,
	GET_DROPSHIPPING_ASSOCIATED_SUCCESS,
	GET_PURCHASE_ASSOCIATED_ERROR,
	GET_PURCHASE_ASSOCIATED_REQUEST,
	GET_PURCHASE_ASSOCIATED_SUCCESS,
	GET_PURCHASE_ID_ASSOCIATED_ERROR,
	GET_PURCHASE_ID_ASSOCIATED_REQUEST,
	GET_PURCHASE_ID_ASSOCIATED_SUCCESS,
	PATCH_DROPSHIPPING_ASSOCIATED_ERROR,
	PATCH_DROPSHIPPING_ASSOCIATED_REQUEST,
	PATCH_DROPSHIPPING_ASSOCIATED_SUCCESS,
	PATCH_PURCHASE_ASSOCIATED_ERROR,
	PATCH_PURCHASE_ASSOCIATED_REQUEST,
	PATCH_PURCHASE_ASSOCIATED_SUCCESS,
	POST_APPROVE_PURCHASE_ASSOCIATED_ERROR,
	POST_APPROVE_PURCHASE_ASSOCIATED_REQUEST,
	POST_APPROVE_PURCHASE_ASSOCIATED_SUCCESS,
	POST_REVIEW_PURCHASE_ASSOCIATED_ERROR,
	POST_REVIEW_PURCHASE_ASSOCIATED_REQUEST,
	POST_REVIEW_PURCHASE_ASSOCIATED_SUCCESS,
	PUT_DROPSHIPPING_ASSOCIATED_ERROR,
	PUT_DROPSHIPPING_ASSOCIATED_REQUEST,
	PUT_DROPSHIPPING_ASSOCIATED_SUCCESS,
	POST_UPLOAD_BUNDLE_INVOICE_REQUEST,
	POST_UPLOAD_BUNDLE_INVOICE_SUCCESS,
	POST_UPLOAD_BUNDLE_INVOICE_ERROR,
} from '../../consts/associatedTypes';

import * as associatedTypes from '../../consts/associatedTypes';

const initialState = {
	isOrdersLoading: false,
	orders: null,
	errorOrder: null,
	isLoadingPutTracking: false,
};

function associatedReducer(state = initialState, action) {
	switch (action.type) {
		case RESET_ASSOCIATED:
			return {};

		case GET_PURCHASE_ASSOCIATED_REQUEST:
			return { ...state, isPurchaseLoading: true, purchase: null, errorPurchase: null, errorPurchaseId: null };
		case GET_PURCHASE_ASSOCIATED_ERROR:
			return {
				...state,
				isPurchaseLoading: false,
				purchase: null,
				errorPurchase: action.error.response?.data?.message,
				errorPurchaseId: null
			};
		case GET_PURCHASE_ASSOCIATED_SUCCESS:
			if (action.resPurchases) {
				return { ...state, isPurchaseLoading: false, purchase: action.resPurchases, errorPurchase: null, errorPurchaseId: null };
			}
			return { ...state, isPurchaseLoading: false, purchase: null, errorPurchase: null, errorPurchaseId: null };

		case GET_PURCHASE_ID_ASSOCIATED_REQUEST:
			return { ...state, isPurchaseIdLoading: true, purchaseId: null, errorPurchaseId: null };
		case GET_PURCHASE_ID_ASSOCIATED_ERROR:
			return {
				...state,
				isPurchaseIdLoading: false,
				purchaseId: null,
				errorPurchaseId: action.error.response?.data?.message,
			};
		case GET_PURCHASE_ID_ASSOCIATED_SUCCESS:
			if (action.resPurchase) {
				return {
					...state,
					isPurchaseIdLoading: false,
					purchaseId: action.resPurchase,
					errorPurchaseId: null,
					reviewSuccess: null,
				};
			}
			return { ...state, isPurchaseIdLoading: false, purchaseId: null, errorPurchaseId: null };

		// -----------------------------------------------------------------------------------------------------------
		case PATCH_PURCHASE_ASSOCIATED_REQUEST:
			return { ...state, isInvoicePurchaseLoading: true, errorInvoicePurchase: null };
		case PATCH_PURCHASE_ASSOCIATED_ERROR:
			return { ...state, isInvoicePurchaseLoading: false, errorInvoicePurchase: action.error.response?.data?.message };
		case PATCH_PURCHASE_ASSOCIATED_SUCCESS:
			return {
				...state,
				isInvoicePurchaseLoading: false,
				errorInvoicePurchase: null,
				purchaseId: null,
				purchase: null,
			};

		case DELETE_ITEM_PURCHASE_ASSOCIATED_REQUEST:
			return { ...state, isDeleteInvoicePurchaseLoading: true, errorDeleteInvoicePurchase: null };
		case DELETE_ITEM_PURCHASE_ASSOCIATED_ERROR:
			return {
				...state,
				isDeleteInvoicePurchaseLoading: false,
				errorDeleteInvoicePurchase: action.error.response?.data?.messagell,
			};
		case DELETE_ITEM_PURCHASE_ASSOCIATED_SUCCESS:
			return {
				...state,
				isDeleteInvoicePurchaseLoading: false,
				errorDeleteInvoicePurchase: null,
				purchaseId: null,
			};

		case POST_APPROVE_PURCHASE_ASSOCIATED_REQUEST:
			return { ...state, isApprovePurchaseLoading: true, errorApprovePurchase: null };
		case POST_APPROVE_PURCHASE_ASSOCIATED_ERROR:
			return { ...state, isApprovePurchaseLoading: false, errorApprovePurchase: action.error.response?.data?.message };
		case POST_APPROVE_PURCHASE_ASSOCIATED_SUCCESS:
			return { ...state, isApprovePurchaseLoading: false, errorApprovePurchase: null, purchaseId: null, purchase: null };

		case POST_REVIEW_PURCHASE_ASSOCIATED_REQUEST:
			return { ...state, isReviewPurchaseLoading: true, errorReviewPurchase: null };
		case POST_REVIEW_PURCHASE_ASSOCIATED_ERROR:
			return { ...state, isReviewPurchaseLoading: false, errorReviewPurchase: action.error.response?.data?.message };
		case POST_REVIEW_PURCHASE_ASSOCIATED_SUCCESS:
			return {
				...state,
				isReviewPurchaseLoading: false,
				errorReviewPurchase: null,
				purchaseId: null,
				reviewSuccess: 1,
				purchase: null,
			};

		// -----------------------------------------------------------------------------------------------------------

		case GET_ORDERS_ASSOCIATED_REQUEST:
			return { ...state, isOrdersLoading: true, orders: null, errorOrder: null };
		case GET_ORDERS_ASSOCIATED_ERROR:
			return { ...state, isOrdersLoading: false, orders: null, errorOrder: action.error.response?.data?.message };
		case GET_ORDERS_ASSOCIATED_SUCCESS:
			if (action.orders) {
				return {
					...state,
					isOrdersLoading: false,
					orders: action.orders,
					errorOrder: null,
					errorPutTracking: null,
					putTracking: null,
				};
			}
			return { ...state, isOrdersLoading: false, orders: null, errorOrder: null };

		case POST_ORDER_UPDATE_TRACKING_ASSOCIATED_REQUEST:
			return { ...state, isLoadingPutTracking: true, putTracking: null, errorPutTracking: null };
		case POST_ORDER_UPDATE_TRACKING_ASSOCIATED_ERROR:
			return {
				...state,
				isLoadingPutTracking: false,
				putTracking: null,
				orders: null,
				errorPutTracking: action.error.response?.data?.message,
			};
		case POST_ORDER_UPDATE_TRACKING_ASSOCIATED_SUCCESS:
			if (action.order) {
				return {
					...state,
					isLoadingPutTracking: false,
					putTracking: action.order,
					errorPutTracking: null,
					orders: null,
					ordersInWarehouseMia: null,
				};
			}
			return { ...state, isLoadingPutTracking: false, putTracking: null, errorPutTracking: null };

		case GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_REQUEST:
			return {
				...state,
				isLoadingOrdersInWarehouseMia: true,
				ordersInWarehouseMia: null,
				errorOrdersInWarehouseMia: null,
			};
		case GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_ERROR:
			return {
				...state,
				isLoadingOrdersInWarehouseMia: false,
				ordersInWarehouseMia: null,
				errorOrdersInWarehouseMia: action.error.response?.data?.message,
			};
		case GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_SUCCESS:
			if (action.ordersInWarehouseMia) {
				return {
					...state,
					isLoadingOrdersInWarehouseMia: false,
					ordersInWarehouseMia: action.ordersInWarehouseMia,
					errorOrdersInWarehouseMia: null,
					putValue: null,
					errorPutValue: null,
				};
			}
			return {
				...state,
				isLoadingOrdersInWarehouseMia: false,
				ordersInWarehouseMia: null,
				errorOrdersInWarehouseMia: null,
			};

		case POST_ORDER_UPDATE_VALUE_ASSOCIATED_REQUEST:
			return { ...state, isLoadingPutValue: true, putValue: null, errorPutValue: null };
		case POST_ORDER_UPDATE_VALUE_ASSOCIATED_ERROR:
			return {
				...state,
				isLoadingPutValue: false,
				putValue: null,
				ordersInWarehouseMia: null,
				errorPutValue: action.error.response?.data?.message,
			};
		case POST_ORDER_UPDATE_VALUE_ASSOCIATED_SUCCESS:
			if (action.value) {
				return {
					...state,
					isLoadingPutValue: false,
					ordersInWarehouseMia: null,
					putValue: action.value,
					errorPutValue: null,
					bundlesFree: null,
				};
			}
			return { ...state, isLoadingPutValue: false, putValue: null, errorPutValue: null };

		// Bulto
		case GET_BUNDLE_REQUEST:
			return {
				...state,
				isBundlesLoading: true,
				bundles: null,
				errorBundles: null,
				errorBundlesFree: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};
		case GET_BUNDLE_ERROR:
			return {
				...state,
				isBundlesLoading: false,
				bundles: null,
				errorBundles: action.error.response?.data?.message,
				errorBundlesFree: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};
		case GET_BUNDLE_SUCCESS:
			if (action.bundles) {
				return {
					...state,
					isBundlesLoading: false,
					bundles: action.bundles,
					errorBundles: null,
					errorPostBundles: null,
					errorDeletBundles: null,
					errorBundlesFree: null,
				};
			}
			return {
				...state,
				isBundlesLoading: false,
				bundles: null,
				errorBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
				errorBundlesFree: null,
			};

		case GET_BUNDLE_ASSOCIATE_REQUEST:
			return { ...state, isBundlesAssociateLoading: true, bundlesAssociate: null, errorBundlesAssociate: null };
		case GET_BUNDLE_ASSOCIATE_ERROR:
			return {
				...state,
				isBundlesAssociateLoading: false,
				bunbundlesAssociatedles: null,
				errorBundlesAssociate: action.error.response?.data?.message,
			};
		case GET_BUNDLE_ASSOCIATE_SUCCESS:
			if (action.bundles) {
				return {
					...state,
					isBundlesAssociateLoading: false,
					bundlesAssociate: action.bundles,
					errorBundlesAssociate: null,
				};
			}
			return { ...state, isBundlesAssociateLoading: false, bundlesAssociate: null, errorBundlesAssociate: null };

		case GET_BUNDLE_FREE_REQUEST:
			return {
				...state,
				isBundlesFreeLoading: true,
				bundlesFree: null,
				errorBundlesFree: null,
				errorBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};
		case GET_BUNDLE_FREE_ERROR:
			return {
				...state,
				isBundlesFreeLoading: false,
				bundlesFree: null,
				errorBundlesFree: action.error.response?.data?.message,
				errorBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};
		case GET_BUNDLE_FREE_SUCCESS:
			if (action.bundlesFree) {
				return {
					...state,
					isBundlesFreeLoading: false,
					bundlesFree: action.bundlesFree,
					errorBundlesFree: null,
					errorBundles: null,
					errorPostBundles: null,
					errorDeletBundles: null,
				};
			}
			return {
				...state,
				isBundlesFreeLoading: false,
				bundlesFree: null,
				errorBundlesFree: null,
				errorBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
			};

		case GET_BUNDLE_PROFIT_REQUEST:
			return { ...state, isBundlesProfitLoading: true, bundlesProfit: null, errorBundlesProfit: null };
		case GET_BUNDLE_PROFIT_ERROR:
			return {
				...state,
				isBundlesProfitLoading: false,
				bundlesProfit: null,
				errorBundlesProfit: action.error.response?.data?.message,
			};
		case GET_BUNDLE_PROFIT_SUCCESS:
			if (action.bundlesProfit) {
				return {
					...state,
					isBundlesProfitLoading: false,
					bundlesProfit: action.bundlesProfit,
					errorBundlesProfit: null,
				};
			}
			return { ...state, isBundlesProfitLoading: false, bundlesProfit: null, errorBundlesProfit: null };

		case POST_BUNDLE_REQUEST:
			return {
				...state,
				isPostBundlesLoading: true,
				postBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};
		case POST_BUNDLE_ERROR:
			return {
				...state,
				isPostBundlesLoading: false,
				postBundles: null,
				errorPostBundles: action.error.response?.data?.message,
				errorDeletBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};
		case POST_BUNDLE_SUCCESS:
			if (action.bundleOk) {
				return {
					...state,
					isPostBundlesLoading: false,
					postBundles: action.bundleOk,
					errorPostBundles: null,
					bundles: null,
					bundlesFree: null,
					errorBundlesFree: null,
					errorBundles: null,
					errorDeletBundles: null,
				};
			}
			return {
				...state,
				isPostBundlesLoading: false,
				postBundles: null,
				errorPostBundles: null,
				errorDeletBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};

		case DELET_BUNDLE_REQUEST:
			return {
				...state,
				isdeletBundlesLoading: true,
				isLoadingAssociatedGlobal: true,
				deletBundles: null,
				errorDeletBundles: null,
				errorPostBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};
		case DELET_BUNDLE_ERROR:
			return {
				...state,
				isdeletBundlesLoading: false,
				isLoadingAssociatedGlobal: false,
				deletBundles: null,
				errorDeletBundles: action.error.response?.data?.message,
				errorPostBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
			};
		case DELET_BUNDLE_SUCCESS:
			if (action.bundleDelete) {
				return {
					...state,
					isdeletBundlesLoading: false,
					isLoadingAssociatedGlobal: false,
					deletBundles: action.bundleDelete,
					errorDeletBundles: null,
					bundlesFree: null,
					bundles: null,
					errorBundlesFree: null,
					errorBundles: null,
					errorPostBundles: null,
				};
			}
			return {
				...state,
				isdeletBundlesLoading: false,
				isLoadingAssociatedGlobal: false,
				deletBundles: null,
				errorDeletBundles: null,
				errorPostBundles: null,
				errorBundlesFree: null,
				errorBundles: null,
				postBundles: null,
			};

		case POST_UPLOAD_BUNDLE_INVOICE_REQUEST:
			return { ...state, isBundleInvoiceLoading: true, currentBundleLoading: action.payload.id, updatedBundle: null, errorUploadInvoice: null };
		case POST_UPLOAD_BUNDLE_INVOICE_ERROR:
			return {
				...state,
				isBundleInvoiceLoading: false,
				updatedBundle: null,
				currentBundleLoading: null,
				errorUploadInvoice: action.error.response?.data?.message,
			};
		case POST_UPLOAD_BUNDLE_INVOICE_SUCCESS:
			if (action.updatedBundle) {
				return {
					...state,
					isBundleInvoiceLoading: false,
					updatedBundle: action.updatedBundle,
					errorUploadInvoice: null,
					currentBundleLoading: null,
				};
			}

			return { ...state, isBundleInvoiceLoading: false, updatedBundle: null, errorUploadInvoice: null, currentBundleLoading: null };
		
		case associatedTypes.PUT_BUNDLE_VALUE_REQUEST:
			return { ...state, isBundleValueLoading: true, currentBundleLoading: action.payload.id, updatedBundle: null, errorUpdatedValue: null };
		case associatedTypes.PUT_BUNDLE_VALUE_ERROR:
			return {
				...state,
				isBundleValueLoading: false,
				updatedBundle: null,
				currentBundleLoading: null,
				errorUpdatedValue: action.error.response?.data?.message,
			};
		case associatedTypes.PUT_BUNDLE_VALUE_SUCCESS:
			if (action.updatedBundle) {
				return {
					...state,
					isBundleValueLoading: false,
					updatedBundle: action.updatedBundle,
					errorUpdatedValue: null,
					currentBundleLoading: null,
				};
			}

			return { ...state, isBundleValueLoading: false, updatedBundle: null, errorUpdatedValue: null, currentBundleLoading: null };

			case GET_BUNDLE_INVOICE_ASSOCIATE_REQUEST:
				return { ...state, isBundlesInvoiceLoading: true, bundlesInvoice: null, errorInvoicesExcel: null };

			case GET_BUNDLE_INVOICE_ASSOCIATE_ERROR:
				return {
					...state,
					isBundlesInvoiceLoading: false,
					bundlesInvoice: null,
					errorInvoicesExcel: action.error.response?.data?.message,
				};
			case GET_BUNDLE_INVOICE_ASSOCIATE_SUCCESS:
				if (action.bundlesInvoice) {
					return {
						...state,
						isBundlesInvoiceLoading: false,
						bundlesInvoice: action.bundlesInvoice,
						errorInvoicesExcel: null,
					};
				}
				return { ...state, isBundlesInvoiceLoading: false, bundlesInvoice: null, errorInvoicesExcel: null };
					
		case GET_CLIENTS_OF_ASSOCIATED_REQUEST:
			return { ...state, isClientsLoading: true, clients: null, errorClients: null };
		case GET_CLIENTS_OF_ASSOCIATED_ERROR:
			return { ...state, isClientsLoading: false, clients: null, errorClients: action.error.response.data.message };
		case GET_CLIENTS_OF_ASSOCIATED_SUCCESS:
			if (action.clients) {
				return { ...state, isClientsLoading: false, clients: action.clients, errorClients: null };
			}
			return { ...state, isClientsLoading: false, clients: null, errorClients: null };

		case GET_CLIENTS_ORDERS_OF_ASSOCIATED_REQUEST:
			return {
				...state,
				isClientsOrdersLoading: true,
				clientsOrders: null,
				errorClientsOrders: null,
				deleteOrder: null,
				errorDeleteOrder: null,
			};
		case GET_CLIENTS_ORDERS_OF_ASSOCIATED_ERROR:
			return {
				...state,
				isClientsOrdersLoading: false,
				clientsOrders: null,
				errorClientsOrders: action.error.response.data.message,
			};
		case GET_CLIENTS_ORDERS_OF_ASSOCIATED_SUCCESS:
			if (action.clients) {
				return { ...state, isClientsOrdersLoading: false, clientsOrders: action.clients, errorClientsOrders: null };
			}
			return { ...state, isClientsOrdersLoading: false, clientsOrders: null, errorClientsOrders: null };

		case GET_PROFIT_PAID_REQUEST:
			return { ...state, isProfitPaidLoading: true, profitPaid: null, errorProfitPaid: null };
		case GET_PROFIT_PAID_ERROR:
			return {
				...state,
				isProfitPaidLoading: false,
				profitPaid: null,
				errorProfitPaid: action.error.response.data.message,
			};
		case GET_PROFIT_PAID_SUCCESS:
			if (action.profit) {
				return { ...state, isProfitPaidLoading: false, profitPaid: action.profit, errorProfitPaid: null };
			}
			return { ...state, isProfitPaidLoading: false, profitPaid: [], errorProfitPaid: null };

		case GET_PROFIT_PENDINGOFPAY_REQUEST:
			return { ...state, isProfitPendingOfPayLoading: true, profitPendingOfPay: null, errorProfitPendingOfPay: null };
		case GET_PROFIT_PENDINGOFPAY_ERROR:
			return {
				...state,
				isProfitPendingOfPayLoading: false,
				profitPendingOfPay: null,
				errorProfitPendingOfPay: action.error.response.data.message,
			};
		case GET_PROFIT_PENDINGOFPAY_SUCCESS:
			if (action.profit) {
				return {
					...state,
					isProfitPendingOfPayLoading: false,
					profitPendingOfPay: action.profit,
					errorProfitPendingOfPay: null,
				};
			}
			return { ...state, isProfitPendingOfPayLoading: false, profitPendingOfPay: [], errorProfitPendingOfPay: null };

		// Order
		case DELET_ORDER_ASSOCIATED_REQUEST:
			return { ...state, isDeleteOrderLoading: true, deleteOrder: null, errorDeleteOrder: null };
		case DELET_ORDER_ASSOCIATED_ERROR:
			return {
				...state,
				isDeleteOrderLoading: false,
				deleteOrder: null,
				errorDeleteOrder: action.error.response?.data?.message,
			};
		case DELET_ORDER_ASSOCIATED_SUCCESS:
			if (action.deleteOrder) {
				return { ...state, isDeleteOrderLoading: false, deleteOrder: action.deleteOrder, errorDeleteOrder: null };
			}
			return {
				...state,
				isDeleteOrderLoading: false,
				deleteOrder: null,
				errorDeleteOrder: null,
				errorClientsOrders: null,
			};

		// Dropshippin
		case GET_DROPSHIPPING_ASSOCIATED_REQUEST:
			return { ...state, isDropshippingLoading: true, dropshipping: null, errorDropshipping: null };
		case GET_DROPSHIPPING_ASSOCIATED_ERROR:
			return {
				...state,
				isDropshippingLoading: false,
				dropshipping: null,
				errorDropshipping: action.error.response.data.message,
			};
		case GET_DROPSHIPPING_ASSOCIATED_SUCCESS:
			if (action.dropshipping) {
				return { ...state, isDropshippingLoading: false, dropshipping: action.dropshipping, errordropshipping: null };
			}
			return { ...state, isDropshippingLoading: false, dropshipping: null, errorDropshipping: null };

		case PATCH_DROPSHIPPING_ASSOCIATED_REQUEST:
			return { ...state, isPatchDropshippingLoading: true, patchDropshipping: null, errorPatchDropshipping: null };
		case PATCH_DROPSHIPPING_ASSOCIATED_ERROR:
			return {
				...state,
				isPatchDropshippingLoading: false,
				patchDropshipping: null,
				errorPatchDropshipping: action.error.response?.data?.message,
			};
		case PATCH_DROPSHIPPING_ASSOCIATED_SUCCESS:
			if (action.response) {
				return {
					...state,
					isPatchDropshippingLoading: false,
					patchDropshipping: action.response,
					errorPatchDropshipping: null,
				};
			}
			return { ...state, isPatchDropshippingLoading: false, patchDropshipping: null, errorPatchDropshipping: null };

		case PUT_DROPSHIPPING_ASSOCIATED_REQUEST:
			return {
				...state,
				isPutDropshippingLoading: true,
				putDropshipping: null,
				errorPutDropshipping: null,
			};
		case PUT_DROPSHIPPING_ASSOCIATED_ERROR:
			return {
				...state,
				isPutDropshippingLoading: false,
				putDropshipping: null,
				errorPutDropshipping: action.error.response?.data?.message,
			};
		case PUT_DROPSHIPPING_ASSOCIATED_SUCCESS:
			if (action.response) {
				return {
					...state,
					isPutDropshippingLoading: false,
					putDropshipping: action.response,
					errorPutDropshipping: null,
				};
			}
			return {
				...state,
				isPutDropshippingLoading: false,
				putDropshipping: null,
				errorPutDropshipping: null,
			};

		case POST_INCOMPLETE_ORDER_REQUEST:
			return {
				...state,
				isLoadingPostIncompleteOrder: true,
				PostIncompleteOrder: null,
				errorPostIncompleteOrder: null,
			};
		case POST_INCOMPLETE_ORDER_ERROR:
			return {
				...state,
				isLoadingPostIncompleteOrder: false,
				PostIncompleteOrder: null,
				orders: null,
				errorPostIncompleteOrder: action.error.response?.data?.message,
			};
		case POST_INCOMPLETE_ORDER_SUCCESS:
			if (action.incompleteOrder) {
				return {
					...state,
					isLoadingPostIncompleteOrder: false,
					PostIncompleteOrder: action.incompleteOrder,
					errorPostIncompleteOrder: null,
					incompleteOrder: null,
					ordersInWarehouseMia: null,
				};
			}
			return {
				...state,
				isLoadingPostIncompleteOrder: false,
				PostIncompleteOrder: null,
				errorPostIncompleteOrder: null,
			};

		default:
			return state;
	}
}

export default associatedReducer;
