import { useRef, useEffect } from 'react';
import { mount } from 'remote/VueComponent';
import { useHistory } from 'react-router-dom';


const NotificationButton = () => {
	const ref = useRef(null);
    const history = useHistory();

	useEffect(() => {
		mount(ref.current, 'Notifications', {
			onOpen: location => history.push(location),
		});
	}, []);

	return <div ref={ref} />;
};

export default NotificationButton;
