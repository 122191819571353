import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import FlightLandIcon from '@mui/icons-material/FlightLand';
import LocalMallIcon from '@mui/icons-material/LocalMall';

const associated = {
	id: 'associated-items',
	title: 'associated',
	type: 'associated',
	children: [
		{
			id: 'new-orders-item',
			title: 'cart',
			type: 'item',
			url: '/',
			icon: ShoppingBasketIcon,
			subItems: false,
			breadcrumbs: false,
		},
		{
			id: 'dropshipping-item',
			title: 'dropshipping',
			type: 'item',
			url: '/dropshipping',
			icon: PointOfSaleIcon,
			subItems: false,
		},
		{
			id: 'warehouse-item',
			title: 'orderStatus',
			type: 'item',
			url: '/estadodepedidos',
			icon: LocalShippingIcon,
			subItems: false,
			// external: true,
			// target: true
		},
		{
			id: 'consolidate-item',
			title: 'consolidationOfPackages',
			type: 'item',
			url: '/consolidaciondepedidos',
			icon: CallMergeIcon,
			subItems: false,
			// external: true,
			// target: true
		},
		{
			id: 'arrival-item',
			title: 'detailByConsolidation',
			type: 'item',
			url: '/detallesporconsolidacion',
			icon: FlightLandIcon,
			breadcrumbs: false,
			subItems: false,
		},
		{
			id: 'bodega-item',
			title: 'warehouse',
			type: 'item',
			url: '/bodega',
			icon: LocalMallIcon,
			breadcrumbs: false,
			subItems: false,
		},
		{
			id: 'profit-item',
			title: 'yourEarnings',
			type: 'item',
			url: '/tusganancias',
			icon: LocalAtmIcon,
			subItems: false,
			// external: true,
			// target: true
		},
		{
			id: 'clients-item',
			title: 'detailByCustomer',
			type: 'item',
			url: '/detalleporcliente',
			icon: ContactMailIcon,
			subItems: false,
			breadcrumbs: false,
		},
	],
};

export default associated;
