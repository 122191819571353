import { useState } from 'react';
import { postSign } from '../../redux/api';

export const useLogOut = () => {
	const [loading, setLoading] = useState(false);

	const logOut = async () => {
		try {
			setLoading(true);
			await postSign('/auth/logout');
			window.dispatchEvent(
				new CustomEvent('logOut', {
					detail: {},
				})
			);
		} catch (error) {}

		setLoading(false);
	};

	return {
		loading,
		logOut,
	};
};
