import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
// import MessageIcon from '@mui/icons-material/Message';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
// Badge
import { Divider, IconButton, LinearProgress, ListItemIcon, ListItemText } from '@mui/material';
import ThemeChanger from '../../../components/ThemeChanger';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { clearUser } from '../../../../core/redux/actions/authActions';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clearAdmin } from '../../../../core/redux/actions/adminActions';
import { clearClient } from '../../../../core/redux/actions/clientActions';
import { clearAssociated } from '../../../../core/redux/actions/associatedActions';
import LanguagePopover from '../../../components/Language/LanguagePopover';
import NotificationButton from '../../../components/NotificationButton';
import { useEffect, useState } from 'react';

import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';
import { useLogOut } from '../../../../core/hooks/auth/useLogOut';

// import logo from '../../assets/img/svg/logo.svg';

function Header(props) {
	const { drawerOpen, drawerToggle, isLoadingAdmin, isLoadingAssociated, isLoadingClient, isLoadingRole, auth, user } =
		props;
	const [isLoadingGlobal, setIsLoadingGlobal] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const history = useHistory();
	const { t } = useTranslation();

	const { logOut: logOutReq } = useLogOut();

	useEffect(() => {
		setIsLoadingGlobal(isLoadingAdmin || isLoadingAssociated || isLoadingClient || isLoadingRole);
	}, [isLoadingAdmin, isLoadingAssociated, isLoadingClient, isLoadingRole]);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const logOut = () => {
		setAnchorEl(null);
		props.logOutRequest();
		logOutReq();
		localStorage.removeItem('snap');
		localStorage.removeItem('optionSelected');
		history.push('/login');
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position='fixed' color='primary' sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
				<Toolbar>
					{auth && (
						<IconButton
							size='large'
							edge='start'
							color='inherit'
							aria-label='menu'
							sx={{ display: { xs: 'block', sm: 'none' }, mr: 2 }}
							onClick={drawerToggle}
						>
							{drawerOpen ? <CloseIcon color='secondary' /> : <MenuIcon />}
						</IconButton>
					)}
					<Typography
						variant='h6'
						component='div'
						sx={{ marginRight: 'auto', '&:hover': { cursor: 'pointer' } }}
						onClick={() => history.push('/')}
					>
						{t('titleApp')}
					</Typography>
					{/* <img src={logo} alt="logo" width="100px"/> */}

					<ThemeChanger />
					<LanguagePopover />
					{auth && <NotificationButton />}

					{auth && (
						<>
							<div>
								<IconButton
									size='large'
									aria-label='account of current user'
									aria-controls='menu-appbar'
									aria-haspopup='true'
									onClick={handleMenu}
									aria-expanded='true'
									color='inherit'
								>
									<AccountCircle />
								</IconButton>
								<Menu
									id='menu-appbar'
									anchorEl={anchorEl}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={Boolean(anchorEl)}
									onClose={handleClose}
									sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
									PaperProps={{
										elevation: 0,
										sx: {
											overflow: 'visible',
											filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
											mt: 4,
											'& .MuiAvatar-root': {
												width: 32,
												height: 32,
												ml: -0.5,
												mr: 1,
											},
											'&:before': {
												content: '""',
												display: 'block',
												position: 'absolute',
												top: 0,
												right: 20,
												width: 10,
												height: 10,
												bgcolor: 'background.paper',
												transform: 'translateY(-50%) rotate(45deg)',
												zIndex: 0,
											},
										},
									}}
								>
									<MenuItem
										onClick={() => {
											handleClose();
											history.push('/perfil');
										}}
									>
										<Avatar />
										<ListItemText
											primary={
												user &&
												user.firstName.charAt(0).toUpperCase() +
													user.firstName.slice(1).toLowerCase() +
													' ' +
													user.lastName.charAt(0).toUpperCase() +
													user.lastName.slice(1).toLowerCase()
											}
											secondary={
												user &&
												(user.role.isAdmin ? t('administrator') : user.role.isAssociate ? t('associated') : t('client'))
											}
										/>
									</MenuItem>
									<Divider />
									<MenuItem onClick={logOut}>
										<ListItemIcon>
											<Logout fontSize='small' />
										</ListItemIcon>
										{t('logOut')}
									</MenuItem>
								</Menu>
							</div>
						</>
					)}
				</Toolbar>

				{isLoadingGlobal && <LinearProgress color='inherit' />}
			</AppBar>
		</Box>
	);
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		logOutRequest: () => {
			dispatch(clearUser());
			dispatch(clearAdmin());
			dispatch(clearClient());
			dispatch(clearAssociated());
		},
	};
};

const mapStateToProps = state => {
	return {
		auth: state.authReducer.token,
		user: state.authReducer.userData,
		isLoadingRole: state.authReducer.isLoadingRole,
		isLoadingAdmin: state.adminReducer.isLoadingAdminGlobal,
		isLoadingAssociated: state.associatedReducer.isLoadingAssociatedGlobal,
		isLoadingClient: state.clientReducer.isLoadingClientGlobal,
	};
};

Header.propTypes = {
	auth: PropTypes.string,
	user: PropTypes.object,
	isLoadingRole: PropTypes.bool,
	isLoadingAdmin: PropTypes.bool,
	isLoadingAssociated: PropTypes.bool,
	isLoadingClient: PropTypes.bool,
	logOutRequest: PropTypes.func.isRequired,
	drawerOpen: PropTypes.bool.isRequired,
	drawerToggle: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
